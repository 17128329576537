/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&family=Sail&display=swap');


// fonts
$base-font-size: 15;
$base-font: 'Jost', sans-serif;
$heading-font: 'Sail', cursive;


// color
$dark-gray: #101010;
$body-color: #323232;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #C8A898;
$theme-primary-color-s2: #657150;
$body-bg-color: #fff;
$section-bg-color: #fbf8f8;
$text-color: #585858;
$text-light-color: #7b7b7b;
$heading-color: $dark-gray;
$border-color: #e7dedf;
$border-color-s2: #e4ebf2;