/*======================================
 6. Home-style-3 
 =======================================*/

/*6.1 wpo-wedding-date-s3 */
.wpo-wedding-date-s3 {


	.wedding-date-wrap {

		.countdown-wrapper {
			display: flex;
			justify-content: space-between;


			@include media-query(1200px) {
				display: flex;
				justify-content: center;
				margin-left: 0;
				flex-wrap: wrap;
			}

			@include media-query(767px) {}

			.time-section {
				width: 312px;
				height: 330px;
				position: relative;
				z-index: 1;
				text-align: center;
				display: flex;
				justify-content: center;
				flex-direction: column;

				@include media-query(1200px) {
					width: 50%;
				}

				@include media-query(767px) {
					width: 100%;
				}

				&:before {
					position: absolute;
					left: 130px;
					top: 60%;
					transform: translate(-50%, -50%);
					width: 100%;
					height: 100%;
					content: "";
					background: url(../../images/wedding-date/1.svg) no-repeat center center;
					z-index: -1;

					@include media-query(1400px) {
						width: 112%;
						height: 112%;
					}

					@include media-query(1200px) {
						left: 40%;
						top: 60%;
					}
				}


				&:nth-child(2) {
					margin-top: 50px;

					@include media-query(1200px) {
						margin-top: 0px;
					}

					&:before {
						left: 130px;
						top: 40%;
						background: url(../../images/wedding-date/2.svg) no-repeat center center;

						@include media-query(1200px) {
							left: 45%;
							top: 40%;
						}
					}
				}

				&:nth-child(3) {
					&:before {
						left: 130px;
						top: 60%;
						background: url(../../images/wedding-date/3.svg) no-repeat center center;

						@include media-query(1200px) {
							left: 45%;
						}
					}
				}

				&:last-child {
					margin-top: 50px;

					@include media-query(1200px) {
						margin-top: 0px;
					}

					&:before {
						left: 130px;
						top: 40%;
						background: url(../../images/wedding-date/4.svg) no-repeat center center;

						@include media-query(1200px) {
							left: 45%;
						}
					}
				}
			}

			>div {
				float: left;
				display: table;

				>div {
					display: table-cell;
					vertical-align: middle;
				}
			}

			.time {
				font-family: $heading-font;
				font-size: 65px;
				font-style: normal;
				font-weight: 400;
				color: $dark-gray;
				line-height: 60px;

				@include media-query(1400px) {
					font-size: 40px;
					line-height: 50px;
				}
			}

			.time-text {
				color: #4D4D4D;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;

				@include media-query(1400px) {
					font-size: 18px;
				}

				@media(max-width:991px) {
					font-size: 16px;
				}
			}
		}

	}
}


/*6.2 wpo-couple-section-s3 */
.wpo-couple-section-s3 {
	.col {
		display: flex;
		align-items: flex-start;

		&:last-child {
			align-items: flex-end;
		}
	}

	.middle-couple-pic {

		.middle-couple-pic-inner {
			mask-image: none;
		}
		.line-shape {
			position: absolute;
			left: 0;
			top: -18px;
		}
	}
}

/*3.3 wpo-story-section-s3 */
.wpo-story-section-s3 {
	padding-bottom: 90px;

	@media (max-width:991px) {
		padding-bottom: 60px;
	}

	@media (max-width:767px) {
		padding-bottom: 50px;
	}

	.wpo-story-item {
		text-align: center;
		margin-bottom: 30px;

		.wpo-story-img-wrap {
			position: relative;
			margin: 0 auto;
			border-radius: 300px;
			border: 1px solid #C8A898;
			max-width: 340px;
			height: 340px;
			display: flex;
			align-items: flex-end;
			z-index: 1;
			margin-bottom: 100px;



			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				background: url(../../images/story/shape3.jpg) no-repeat center bottom;
				bottom: -75px;
			}

			.wpo-story-img {

				max-width: 300px;
				max-height: 300px;
				margin: 0 auto;

				img {
					width: 100%;
					height: 100%;
					border-radius: 300px;
					border: 1px solid #C8A898;
				}
			}
		}

		.wpo-story-content {

			.wpo-story-content-inner {
				span {
					color: #C8A898;
					font-size: 18px;
					font-weight: 400;
				}

				h2 {
					color: #101010;
					font-size: 40px;
					font-weight: 400;

					@media (max-width:1199px) {
						font-size: 30px;
					}
				}

				p {
					color: #5C5C5C;
					font-size: 18px;
					font-weight: 400;
					line-height: 30px;
					margin-bottom: 0;
				}
			}
		}

	}
}


/*6.4 wpo-contact-section-s3 */
.wpo-contact-section-s3 {
	padding: 180px 0;

	@media(max-width:991px) {
		padding: 90px 0;
	}

	.wpo-contact-section-wrapper {
		max-width: 950px;
		position: relative;
		z-index: 11;

		.wpo-contact-section-inner {
			background: #F8F3F0;
			border-radius: 0px;


			@media(max-width:575px) {
				padding: 15px;
			}
		}

		.wpo-contact-form-area {
			border: 1px solid transparent;
			border-radius: 0px;
			padding: 0px 180px 50px;
			position: relative;
			z-index: 11;

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: -11;
				content: '';
				border: 1px solid #C8A898;
			}

			@media(max-width:1199px) {
				padding: 0px 150px 50px;
			}

			@media(max-width:991px) {
				padding: 0px 80px 50px;
			}

			@media(max-width:767px) {
				padding: 80px 40px 70px;
			}

			@media(max-width:440px) {
				padding: 60px 20px 30px;
			}
		}

	}

	.wpo-contact-section-wrapper {

		.wpo-section-title {

			h2 {
				color: #101010;

				&::before {
					display: none;
				}

				&::after {
					display: none;
				}

			}
		}

		.wpo-contact-form-area {

			.form-control {
				border-bottom: 1px solid #C0C0C0;
				color: #464646;

			}

			.form-control::-webkit-input-placeholder {
				/* Edge */
				color: #464646;
			}

			.form-control:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #464646;
			}

			.form-control::placeholder {
				color: #464646;
			}


			select.form-control {
				color: #464646;

				option {
					color: #464646;
				}
			}

			.radio-buttons {
				p {
					color: #464646;
				}


				[type="radio"]:checked+label,
				[type="radio"]:not(:checked)+label {
					color: #464646;
				}

				[type="radio"]:checked+label:before,
				[type="radio"]:not(:checked)+label:before {
					height: 18px;
					border: 1px solid $theme-primary-color;
				}

				[type="radio"]:checked+label:after,
				[type="radio"]:not(:checked)+label:after {
					background: $theme-primary-color;
				}


			}
		}

		.shape-1 {
			right: -165px;
			top: -115px;
			position: absolute;

			@media(max-width:991px) {
				width: 300px;
				right: -100px;
				top: -75px;
			}
		}

		.shape-2 {
			bottom: -190px;
			left: -270px;
			position: absolute;

			@media(max-width:991px) {
				width: 300px;
				bottom: -100px;
				left: -100px;
			}
		}
	}

}

/*6.5 wpo-contact-map-section */
.wpo-contact-map-section {
	.wpo-contact-map {
	    height: 550px;
	}

    iframe {
	    width: 100%;
	    height: 100%;
	    border: 0;
	    outline: 0;
		filter: grayscale(100%);
	}

	h2.hidden{
		display: none;
	}
}