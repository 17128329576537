/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer-s2 {
    position: relative;
    font-size: 15px;
    overflow: hidden;
    background-size: cover;
    background-position: center;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: $black;
        opacity: .8;
    }

    ul {
        list-style: none;
    }

    p {
        color: $white;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 100px 120px;
        position: relative;
        z-index: 1;

        @media(max-width:1800px) {
            padding: 80px 50px;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 60px 30px 0;
        }
    }

    @media (max-width: 767px) {
        .wpo-upper-footer {
            padding: 30px 20px 0;
        }
    }


    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 30px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 400;

            @media (max-width: 991px) {
                font-size: 25px;
            }
        }
    }

    .about-widget {
        .logo {
            font-size: 45px;
            font-family: $heading-font;
            color: $white;
            line-height: 40px;

            @media(max-width:991px) {
                font-size: 40px;
            }

            span {
                position: relative;

                i {
                    position: absolute;
                    left: 1px;
                    top: 3px;
                    font-size: 13px;
                }
            }
        }

    }

    .about-widget {
        padding-right: 45px;

        @media(max-width:1500px) {
            padding-right: 15px;
        }

        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            overflow: hidden;
            padding-top: 10px;

            li {
                font-size: 22px;
                float: left;

                a {
                    color: $theme-primary-color;
                    width: 42px;
                    height: 42px;
                    line-height: 46px;
                    background: $white;
                    display: block;
                    text-align: center;
                    @include rounded-border(50%);
                    font-size: 18px;

                    &:hover {
                        background: $white;
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {
                margin-left: 15px;

                @media screen and (max-width: 1600px) {
                    margin-left: 10px;
                }

            }
        }
    }


    .link-widget {
        overflow: hidden;

        .link-wrap {
            display: flex;

            ul {
                &+ul {
                    margin-left: 50px;
                }
            }
        }

        ul {
            li {
                position: relative;

                a {
                    color: $white;
                    font-size: 18px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }

    .contact-ft {
        margin-top: 20px;
        padding-right: 50px;

        @media(max-width:1500px) {
            padding-right: 0px;
        }

        ul {
            li {
                padding-bottom: 15px;
                position: relative;
                padding-left: 0;
                color: $white;
                font-size: 18px;

                i {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .fi:before {
                    font-size: 17px;
                    margin-right: 15px;
                }
            }
        }
    }



    .wpo-lower-footer {
        text-align: center;
        position: relative;
        border-top: 1px solid #585858;

        .row {
            padding: 20px 0;
            position: relative;
        }

        .copyright {
            display: inline-block;
            font-size: 18px;
            margin: 0;
            color: $white;

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }

    .instagram {
        @media(max-width:1200px) {
            margin-top: 40px;
        }

        @media(max-width:991px) {
            margin-top: 0px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.wpo-site-footer-s2 {
    
    .wpo-upper-footer {
        padding: 150px 0;
        @media (max-width: 991px) {
            padding: 100px 0;
        }
    
        @media (max-width: 767px) {
            padding: 80px 0;
        }
    }


    &:before {
        display: none;
    }

    .wpo-upper-wrap {
        position: relative;
        max-width: 320px;
        height: 320px;
        background: $white;
        margin: 0 auto;
        text-align: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 575px) {
            max-width: 280px;
            height: 280px;
        }


        h3 {
            color: #000;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 575px) {
                font-size: 40px;
            }
        }

        p {
            color: #000;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 155%;
            margin-bottom: 0;

            @media (max-width: 575px) {
                font-size: 20px;
            }
        }

        .shape1 {
            position: absolute;
            right: 0px;
            top: -30px;
        }

        .shape2 {
            position: absolute;
            left: 0px;
            bottom: -30px;
        }
    }

    .wpo-lower-footer {
        border: 0;
        background: rgba($white, .5);

        .copyright {
            color: #111;

            a {
                color: #111;
            }
        }
    }
}