/* 3.2 wpo-partners-section */

.wpo-partners-section {

	@media(max-width:991px) {
		padding-bottom: 50px;
	}

	@media(max-width:767px) {
		padding-bottom: 30px;
	}

	.container {
		position: relative;
	}


	.grid {
		text-align: center;
		height: 100px;
		display: flex !important;
		justify-content: center;
		flex-direction: column;

		@media(max-width:991px) {
			height: 80px;
		}
	}


	.grid img {
		width: auto;
		margin: 0 auto;
		display: inline-block;
	}

	.owl-nav {
		display: none;
	}
}

/* 3.3 wpo-couple-section */
.wpo-couple-section,
.wpo-couple-section-s2,
.wpo-couple-section-s3 {
	padding: 120px 100px 180px;

	@media(max-width:1700px) {
		padding: 120px 30px;
	}

	@media(max-width:991px) {
		padding: 90px 30px;
	}

	@media(max-width:575px) {
		padding: 80px 0px;
	}

	.col {
		@media(min-width:991px) {
			padding: 0;
		}
	}

	.middle-couple-pic {
		margin: 0 auto;
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 20px;
		background: $white;
		border-radius: 50%;
		z-index: 1;
		

		@media(max-width:1199px) {
			max-width: 530px;
		}

		@media(max-width:575px) {
			max-width: 350px;
		}



		.couple-flower {
			position: absolute;
			left: 0;
			bottom: -75px;

			@media(max-width:1440px) {
				bottom: -55px;
			}

			@media(max-width:1199px) {
				bottom: -40px;
			}
		}

		.middle-couple-pic-inner {
			overflow: hidden;
			mask-image: url(../../images/couple/shape.png);
			-webkit-mask-image: url(../../images/couple/shape.png);
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			max-width: 533px;
			margin: 0 auto;

			@media(max-width:1440px) {
				max-width: 415px;
			}

			@media(max-width:1199px) {
				max-width: 230px;
			}

			@media(max-width:991px) {
				max-width: 415px;
			}

			img {
				width: 100%;
				height: 100%;
				-webkit-animation: kenburn 20s 1.2s alternate infinite;
				-animation: kenburn 20s 1.2s alternate infinite;
			}
		}

		@include media-query(991px) {
			margin: 50px auto;
			margin-bottom: 90px;
		}

		@include media-query(575px) {
			margin: 0 auto;
			margin-bottom: 20px;
		}

		.bottom-shadow {
			position: absolute;
			left: 5px;
			top: -20px;
			z-index: -1;
			max-width: 660px;

			@media(max-width:1440px) {
				max-width: 530px;
				left: 20px;
			}

			@media(max-width:1199px) {
				left: 0;
			}

			@media(max-width:1199px) {
				max-width: 530px;
			}

			@media(max-width:575px) {
				max-width: 100%;
				top: 0;
			}

		}

	}

	.text-grid {
		text-align: center;

		@include media-query(991px) {
			max-width: 460px;
			margin: 0 auto;
		}

		h3 {
			font-size: 50px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin: 0px 0 20px;

			@media(max-width:1399px) {
				font-size: 40px;
			}

			@media(max-width:575px) {
				font-size: 30px;
			}
		}

		p {
			font-size: 17px;
			font-style: normal;
			font-weight: 400;
			line-height: 155%;

			@media(max-width:575px) {
				font-size: 16px;
			}
		}

		.vector {
			animation: vector-animation 6s infinite;
		}

		@keyframes vector-animation {
			0% {
				opacity: 1;
			}

			20% {
				opacity: .3;
			}

			50% {
				opacity: .5;
			}

			70% {
				opacity: .7;
			}

			90% {
				opacity: .8;
			}

			100% {
				opacity: 1;
			}
		}
	}


	ul {
		list-style: none;
		overflow: hidden;
		padding-top: 15px;
		justify-content: center;
		display: flex;

		@include media-query(991px) {
			padding-top: 10px;
			justify-content: center;
		}

		li {
			float: left;
		}

		>li+li {
			margin-left: 25px;
		}

		li a {
			display: block;
			text-align: center;
			color: $theme-primary-color-s2;

			&:hover {
				color: $theme-primary-color;
			}
		}
	}

	.couple-area {
		.text-grid {
			text-align: right;
			max-width: 330px;
			margin-left: auto;
			margin-right: 30px;

			@media(max-width:991px) {
				margin: 0 auto;
				text-align: center;
				margin-bottom: 30px;
			}

			ul {
				justify-content: flex-end;

				@media(max-width:991px) {
					justify-content: center;
				}

			}
		}
	}

	.col {
		&:nth-child(3) {
			.text-grid {
				text-align: left;
				margin: 0;
				margin-left: 30px;

				@media(max-width:991px) {
					margin: 0 auto;
					text-align: center;
					margin-bottom: 0px;
				}

				ul {
					justify-content: flex-start;

					@media(max-width:991px) {
						justify-content: center;
					}
				}
			}
		}
	}
}

/* 3.4 wpo-video-section*/

.wpo-video-section {
	position: relative;
	z-index: 1;
	background: url(../../images/cta.jpg) no-repeat center center;
	height: 540px;
	background-attachment: fixed;
	z-index: 99;

	@media(max-width:767px) {
		max-height: 350px;
	}

	.container {
		@media(min-width:1600px) {
			max-width: 1400px;
		}
	}

	.video-btn button.wrap{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100px;
		height: 100px;
		border: 5px solid $white;
		text-align: center;
		border-radius: 50%;
		line-height: 92px;
		background: rgba(153, 153, 153, 0.30);

		@include media-query(575px) {
			width: 80px;
			height: 80px;
			line-height: 75px;
		}

		.fi {
			position: relative;
			right: -3px;

			@include media-query(575px) {
				right: 0;
			}

			&:before {
				font-size: 30px;
				color: $white;

				@include media-query(575px) {
					font-size: 20px;
				}
			}
		}
	}
}

/* 3.5 wpo-story-section */

.wpo-story-section,
.wpo-story-section-s2 {
	position: relative;
	z-index: 1;
	overflow: hidden;

	.wpo-story-item {
		display: flex;
		align-items: center;

		@media(max-width:991px) {
			flex-wrap: wrap;
			padding-top: 80px;

			&:first-child {
				padding-top: 0;
			}
		}

		@media(max-width:575px) {
			padding-top: 50px;
		}

		&+.wpo-story-item {
			margin-top: -150px;

			@media(max-width:991px) {
				margin-top: 0;
			}
		}


		.wpo-story-img-wrap {
			position: relative;
			z-index: 1;
			max-width: 450px;
			text-align: center;

			@media(max-width:991px) {
				flex-basis: 100%;
				margin: 0 auto;
			}

			@media(max-width:575px) {
				max-width: 400px;
			}

			.wpo-story-img {
				border-radius: 50%;
				position: relative;

				img {
					border-radius: 50%;
				}

				&:before {
					position: absolute;
					left: 4%;
					top: 3%;
					width: 92%;
					height: 94%;
					border-radius: 50%;
					content: "";
					border: 1px solid $white;
					z-index: 1;
				}
			}

			.wpo-img-shape {
				position: absolute;
				width: auto;
				left: -80px;
				bottom: -80px;
				z-index: 1;

				@media(max-width:991px) {
					width: 105%;
					max-width: 450px;
					margin: 0 auto;

				}

				@media(max-width:450px) {
					width: 130%;

				}
			}

		}

		.wpo-story-content {
			flex-basis: 50%;
			position: relative;
			max-width: 385px;
			margin-left: 80px;

			@media(max-width:991px) {
				padding-left: 0px;
				margin-left: 0;
				text-align: center;
				margin: 0 auto;
				flex-basis: 100%;
				position: relative;
				max-width: 100%;
				margin-top: 70px;
			}

			@media(max-width:575px) {
				margin-top: 30px;
				padding: 10px;
			}


			.wpo-story-content-inner {
				position: relative;

				h2 {
					font-size: 40px;
					font-style: normal;
					font-weight: 400;
					margin-bottom: 20px;

					@media(max-width:1199px) {
						margin-bottom: 20px;
						font-size: 30px;
						line-height: 36px;
					}

					@media(max-width:575px) {
						font-size: 25px;
						margin-bottom: 10px;
					}
				}

				span {
					font-style: normal;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					color: $theme-primary-color-s2;
					margin-bottom: 10px;
					display: block;

					@media(max-width:1199px) {
						margin-bottom: 10px;
					}

					@media(max-width:575px) {
						font-size: 18px;
					}
				}

				p {
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 30px;
					color: $text-color;
					margin-bottom: 0;

					@media(max-width:1399px) {
						font-size: 18px;
					}

					@media(max-width:575px) {
						font-size: 17px;
					}
				}
			}


		}

		&:nth-child(even) {
			.wpo-story-img-wrap {
				order: 2;
				-webkit-order: 2;
				-moz-order: 2;

				@media(max-width:991px) {
					order: unset;
					-webkit-order: unset;
					-moz-order: unset;
				}

				.wpo-img-shape {
					position: absolute;
					width: auto;
					left: auto;
					right: -80px;
				}
			}

			.wpo-story-content {
				order: 1;
				-webkit-order: 1;
				-moz-order: 1;
				flex: 1;
				margin-left: 0;
				position: relative;
				padding-left: 0;
				margin-left: auto;
				margin-right: 80px;


				@media(max-width:991px) {
					padding: 20px;
					order: unset;
					-webkit-order: unset;
					-moz-order: unset;
					flex-basis: 100%;
					margin-right: 0;
					text-align: center;
					flex-basis: 100%;
					position: relative;
					max-width: 100%;
				}

				@media(max-width:575px) {
					padding: 10px;
					margin-top: 30px;
				}

				.wpo-story-content-inner {
					position: relative;
					text-align: right;
					max-width: 450px;

					@media(max-width:991px) {
						max-width: 100%;
						text-align: center;
					}

				}


			}
		}
	}
}


/* 3.6 wpo-portfolio-section*/

.wpo-portfolio-section {

	.portfolio-grids {
		.grid {

			img {
				width: 100%;
			}
		}
	}

	.grid {
		.img-holder {
			position: relative;

			.hover-content {
				transition: all .3s;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				text-align: center;
				width: 260px;
				cursor: pointer;

				span {
					color: #6788a7;
				}

				h4 {
					font-size: 30px;
					font-weight: 600;

					a {
						color: $dark-gray;

						&:hover {
							color: $theme-primary-color;
						}
					}
				}

				i {
					font-size: 30px;
					color: $dark-gray;
				}
			}

			&:before {
				position: absolute;
				left: 2%;
				top: 2%;
				width: 96%;
				height: 96%;
				content: "";
				background: rgba(255, 255, 255, 0.8);
				opacity: 0;
				transition: all .3s;
				transform: scale(0);
			}
		}
	}

	.img-holder:hover .hover-content {
		opacity: 1;
	}

	.img-holder:hover {
		&:before {
			opacity: 1;
			transform: scale(1);
		}

	}

}


/* 3.7 wpo-team-section */

.wpo-team-section {
	padding-bottom: 90px;
	overflow: hidden;

	@media(max-width:991px) {
		padding-bottom: 70px;
	}

	@media(max-width:575px) {
		padding-bottom: 30px;
	}

	.wpo-team-wrap {
		.wpo-team-item {
			text-align: center;
			margin-bottom: 30px;

			.wpo-team-img {
				position: relative;
				overflow: hidden;
				border-radius: 50%;
				max-width: 300px;
				margin: 0 auto;

				&:before {
					position: absolute;
					left: 4%;
					top: 3%;
					width: 92%;
					height: 94%;
					border-radius: 50%;
					content: "";
					border: 1px solid $white;
					z-index: 1;
				}

				img {
					border-radius: 50%;
					transform: scale(1);
					transition: all .3s;

				}
			}

			&:hover {
				.wpo-team-img {
					img {
						transform: scale(1.2);
					}
				}
			}

			.wpo-team-text {
				padding-top: 20px;

				h3 {
					font-size: 35px;
					line-height: 36px;
					font-weight: 400;
					margin-bottom: 15px;

					@media(max-width:1199px) {
						font-size: 25px;
					}

					a {
						color: $dark-gray;

						&:hover {
							color: $theme-primary-color;
						}
					}
				}

				span {
					font-size: 18px;
					color: $theme-primary-color-s2;
				}
			}
		}
	}
}


/* 3.8 wpo-contact-section */

.wpo-contact-section,
.wpo-contact-section-s2,
.wpo-contact-section-s3 {
	position: relative;
	z-index: 1;
	overflow: hidden;
	background-attachment: fixed !important;
	background-size: cover;
	background-position: center;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		background: $black;
		opacity: .5;
	}

	.wpo-contact-title {
		max-width: 440px;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 30px;

		h2 {
			font-size: 50px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			color: $white;

			@media(max-width:575px) {
				font-size: 35px;
				margin-bottom: 15px;
			}
		}

		p {
			color: $white;

			@media(max-width:575px) {
				font-size: 16px;
			}
		}
	}

	.wpo-contact-section-wrapper {
		position: relative;
		margin: 0 auto;
		z-index: 1;
		max-width: 1100px;

		.wpo-section-title {
			padding: 60px 0;
			position: relative;
			z-index: 1;
			margin-bottom: 20px;

			@media(max-width:575px) {
				padding: 30px 0;
			}

			h2 {
				font-size: 40px;
				font-style: normal;
				font-weight: 400;

				@media(max-width:575px) {
					font-size: 30px;
				}
			}

			@media(max-width:1200px) {
				margin-bottom: 20px;
			}
		}

		.wpo-contact-form-area {
			position: relative;
			z-index: 99;
			padding-top: 0;

			.form-control {
				width: 100%;
				height: 60px;
				margin-bottom: 20px;
				background: transparent;
				border: 0;
				border-bottom: 1px solid #D9D9D9;
				color: $white;
				border-radius: 0;
				font-size: 18px;
				padding-left: 0;


				&:focus {
					outline: none;
					box-shadow: none;
				}

				&::-webkit-calendar-picker-indicator {
					filter: invert(1);
				}

			}

			.form-control::-webkit-input-placeholder {
				/* Edge */
				color: $white;
			}

			.form-control:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: $white;
			}

			.form-control::placeholder {
				color: $white;
			}


			select.form-control {
				color: $white;
				-webkit-appearance: none;
				-ms-appearance: none;
				-o-appearance: none;
				appearance: none;
				-moz-appearance: none;
				background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
				position: relative;

				option {
					color: $text-color;
				}
			}

			.date {
				.form-control {
					-webkit-appearance: none;
					-ms-appearance: none;
					-o-appearance: none;
					appearance: none;
					-moz-appearance: none;
					background: transparent url(../../images/date.png) no-repeat calc(100% - 15px) center;
				}
			}

			.radio-buttons {
				padding: 20px 0;

				p {
					font-size: 18px;
					color: $white;

					&+p {
						margin-left: 30px;

						@media(max-width:575px) {
							margin-left: 0px;
						}
					}
				}

				[type="radio"]:checked,
				[type="radio"]:not(:checked) {
					position: absolute;
					left: -9999px;
				}

				[type="radio"]:checked+label,
				[type="radio"]:not(:checked)+label {
					position: relative;
					padding-left: 28px;
					cursor: pointer;
					line-height: 20px;
					display: inline-block;
					color: $white;
				}

				[type="radio"]:checked+label:before,
				[type="radio"]:not(:checked)+label:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 18px;
					height: 18px;
					border: 1px solid #ddd;
					border-radius: 100%;
					background: transparent;
				}

				[type="radio"]:checked+label:after,
				[type="radio"]:not(:checked)+label:after {
					content: '';
					width: 12px;
					height: 12px;
					background: $white;
					position: absolute;
					top: 3px;
					left: 3px;
					border-radius: 100%;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}

				[type="radio"]:not(:checked)+label:after {
					opacity: 0;
					-webkit-transform: scale(0);
					transform: scale(0);
				}

				[type="radio"]:checked+label:after {
					opacity: 1;
					-webkit-transform: scale(1);
					transform: scale(1);
				}
			}

			.errorMessage {
				color: red;
			}


			.select.last {
				margin-bottom: 50px;
			}

			.submit-area {
				margin-top: 40px;
				text-align: center;
			}
		}
	}
}


/* 3.9 wpo-event-section */

.wpo-event-section,
.wpo-event-section-s2 {
	padding-bottom: 90px;

	@media(max-width:991px) {
		padding-bottom: 80px;
	}

	@media(max-width:767px) {
		padding-bottom: 70px;
	}

	.wpo-event-item {
		text-align: center;
		max-width: 410px;
		margin: 0 auto;
		margin-bottom: 30px;

		.wpo-event-img {
			.wpo-event-img-inner {
				overflow: hidden;

				img {
					transition: all .3s;
					transform: scale(1);
				}
			}
		}

		&:hover {
			.wpo-event-img {
				.wpo-event-img-inner {
					img {
						-webkit-transform: scale(1.2) rotate(5deg);
						-ms-transform: scale(1.2) rotate(5deg);
						transform: scale(1.2) rotate(5deg);
					}
				}
			}
		}

		.wpo-event-text {
			max-width: 368px;
			background: $white;
			position: relative;
			margin: 0 auto;
			margin-top: -80px;
			padding: 20px 50px 0;

			@media(max-width:1399px) {
				max-width: 330px;
			}

			@media(max-width:1199px) {
				max-width: 270px;
				padding: 20px 30px 0;
			}


			h2 {
				font-size: 35px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border-bottom: 1px solid $theme-primary-color;
				padding-bottom: 20px;

				@media(max-width:1199px) {
					font-size: 30px;
				}

				@media(max-width:757px) {
					font-size: 25px;
				}
			}

			ul {
				list-style: none;
				max-width: 245px;
				margin: 0 auto;
				margin-top: 30px;

				li {
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 150.2%;
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}

					button {
						color: $theme-primary-color;
						position: relative;
						display: inline-block;
						margin-top: 10px;
						font-size: 18px;
						text-transform: capitalize;
						background: none;
						box-shadow: none;

						&::before {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
							content: "";
							background: $theme-primary-color;
						}
					}
				}
			}
		}
	}
}


/* 3.10 wpo-cta-section */

.wpo-cta-section,
.wpo-cta-section-s2 {
	padding: 0 150px;

	@media(max-width:1599px) {
		padding: 0 50px;
	}

	@media(max-width:1199px) {
		padding: 0 30px;
	}

	@media(max-width:991px) {
		padding: 0;
	}

	.wpo-cta-item {
		padding: 145px 0;
		text-align: center;
		background: url(../../images/cta2.jpg) no-repeat center center;
		position: relative;
		z-index: 1;
		background-attachment: fixed;
		overflow: hidden;

		@media(max-width:991px) {
			padding: 90px 15px;
		}

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $black;
			content: "";
			opacity: .30;
			z-index: -1;
		}

		h2 {
			font-size: 50px;
			font-style: normal;
			font-weight: 400;
			color: $white;
			margin: 10px 0 30px;

			@media(max-width:991px) {
				font-size: 60px;
			}

			@media(max-width:767px) {
				font-size: 40px;
			}

			@media(max-width:480px) {
				font-size: 30px;
				margin: 10px 0 20px;
			}
		}

		p {
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 155%;
			color: $white;
			max-width: 480px;
			margin: 0 auto;
			margin-bottom: 30px;
		}
	}
}


/* 3.11 wpo-blog-section */

.wpo-blog-section {
	padding-bottom: 90px;

	@media(max-width:767px) {
		padding-bottom: 60px;
	}

	.wpo-blog-item {
		margin-bottom: 30px;

		.wpo-blog-img {
			overflow: hidden;

			img {
				width: 100%;
				-webkit-filter: grayscale(0);
				-moz-filter: grayscale(0);
				-o-filter: grayscale(0);
				-ms-filter: grayscale(0);
				filter: grayscale(0);
				transition: all .3s;
				transform: scale(1);
			}
		}

		&:hover {
			.wpo-blog-img {
				img {
					-webkit-filter: grayscale(100%);
					-moz-filter: grayscale(100%);
					-o-filter: grayscale(100%);
					-ms-filter: grayscale(100%);
					filter: grayscale(100%);
					transform: scale(1.2);
				}
			}
		}


		.wpo-blog-content {
			padding-top: 20px;
			text-align: center;

			ul {
				list-style: none;
				display: flex;
				margin-bottom: 15px;
				justify-content: center;

				li {
					color: #5C5C5C;
					font-size: 17px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					&:first-child {
						padding-right: 30px;
						position: relative;

						&:before {
							position: absolute;
							right: 10px;
							top: 50%;
							transform: translateY(-50%);
							width: 6px;
							height: 6px;
							content: "";
							background: $theme-primary-color-s2;
							border-radius: 50%;
						}
					}

					a {
						color: $theme-primary-color-s2;

						&:hover {
							color: $theme-primary-color;
						}
					}
				}
			}

			h2 {
				font-size: 35px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 10px;
				margin-bottom: 20px;

				@media(max-width:1200px) {
					font-size: 25px;
					margin-bottom: 10px;
				}

				a {
					color: $heading-color;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}

			.read-more {
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				color: $theme-primary-color;

				@include media-query(991px) {
					font-size: 16px;
					font-size: calc-rem-value(16);
				}
			}


		}
	}
}